import type { AppData } from '../../viewer.app';
import model from './model';

interface DefaultPlugin {
  set resourceId(postId: string);
}

const ui = {
  root: '#relatedPostsRoot',
  slotContainer: '#page-bottom-1',
} as const;

export default model.createController(({ $w, flowAPI, appData: _appData }) => {
  const appData = _appData as AppData;

  return {
    pageReady: async () => {
      await appData.subjects.postPageRenderModel.subscribe((data) => {
        try {
          const root = $w(ui.root) as $w.Box;

          if (root.hidden) {
            return;
          }

          const slotContainer = $w(
            ui.slotContainer,
          ) as $w.SlotsPlaceholder<DefaultPlugin>;
          if (slotContainer.slot) {
            slotContainer.slot.resourceId = data.post.id;
          }
        } catch (err) {
          console.info(err);
        }
      });
    },
    exports: {},
  };
});
